import React, { Suspense, lazy } from 'react';
import { CookiesProvider } from 'react-cookie';
import { I18nextProvider } from 'react-i18next';
import Loading from './components/loading/Loading';
import i18n from './i18n';
const AppContainer = lazy(() => import('./containers/App'));
const App = () => (React.createElement(Suspense, { fallback: React.createElement(Loading, null) },
    React.createElement(CookiesProvider, null,
        React.createElement(I18nextProvider, { i18n: i18n },
            React.createElement(AppContainer, null)))));
export default App;
