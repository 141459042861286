import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
if (process.env.NODE_ENV === 'production') {
    serviceWorker.register();
}
else {
    serviceWorker.unregister();
}
ReactDOM.render(React.createElement(App, null), document.querySelector('#root'));
