import React from 'react';
import './loading.css';

// This Component does not use any styling library as it's intended to also be used
// for when a client is still downloading the rest of the bundles and should be kept as
// small as possible
const Loading = props => (
  <div
    style={{
      alignItems: 'center',
      backgroundColor: 'rgba(0, 26, 60, .9)',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      left: '0',
      position: 'fixed',
      top: '0',
      width: '100%',
    }}
  >
    <div className="loader" />
  </div>
);

export default Loading;
